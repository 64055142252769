import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthenticationService } from "./core/authentication/authentication.service";
import { LoaderService } from "./core/services";
// import { ToasterConfig } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { environment } from "src/environments/environment";

export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  public interval: any;
  public currentPath: any;
  public encryptedData: any;
  public csrfToken: any;
  private subscription: Subscription = new Subscription();
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.encryptedData = new URLSearchParams(window.location.search).get(
      "csrfToken"
    );
    this.csrfToken = new URLSearchParams(window.location.search).get("id");
    if (this.csrfToken) {
      sessionStorage.setItem("csrfToken", this.csrfToken);
    }
    let token = sessionStorage.getItem("csrfToken");
    this.subscription.add(
      router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentPath = this.router.url;
          console.log("token is ", token);

          // if(token == "null"){
          //   token = null
          // }
          var updatedURL = this.router.url.replace(/[?&]id=([^&#]*)/, "");
          if (
            !this.encryptedData &&
            !token &&
            updatedURL != "/auth/admin/reset-password"
          ) {
            window.location.href = environment.alarmPermitHost;
          }
        }
      })
    );
  }

  ngOnInit(): void {
    NgMultiSelectDropDownModule;
    this.subscription.add(
      this.authenticationService.obsLoginDetails.subscribe((data: any) => {
        if (data && data.isUser) {
          // this.subscription.add(
          //   this.authenticationService.getCSRFToken().subscribe((data: any) => {
          //     sessionStorage.setItem("csrfToken", data);
          //     console.log("data set ===>",)
          //   })
          // );
          this.intervalFunction();
        } else {
          this.subscription.add(
            this.authenticationService.getUserInfo().subscribe((user) => {
              console.log("data is user", user);
              const currentApplicantUser = user ? user : null;
              if (currentApplicantUser && currentApplicantUser.isUser) {
                // console.log("data is ===>",    window.location.href = "https://ap.appening.xyz/",currentApplicantUser.isUser)
                // window.location.href = "https://ap.appening.xyz/",
                // this.subscription.add(
                //   this.authenticationService
                //     .getCSRFToken()
                //     .subscribe((data: any) => {
                //       sessionStorage.setItem("csrfToken", data);
                //       console.log("data set ===>",)

                //     })
                // );
                this.intervalFunction();
              } else {
              }
            })
          );
        }
      })
    );
    this.subscription.add(
      this.authenticationService.obsLoggedOutBs.subscribe((data: any) => {
        if (data) {
          clearInterval(this.interval);
        }
      })
    );
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 7000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  intervalFunction() {
    this.interval = setInterval(() => {
      this.subscription.add(
        this.authenticationService.getCSRFToken().subscribe((data: any) => {
          sessionStorage.setItem("csrfToken", data);
        })
      );
    }, 50000);
  }
  // title = 'alarm-permit';
}
